import { useStaticQuery, graphql } from "gatsby"
export const  useGalleryData = () => {
  const data = useStaticQuery(
    graphql`
      {
        pagesGalleryYaml {
          heading {
            headingText
            subHeadingText
            subHeadingMaxWidth
          }
          gallery {
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
                fixed(width: 1000) {
                  src
                  aspectRatio
                  width
                  height
                }
              }
            }
          }
        }
      }
    `
  )
  return data.pagesGalleryYaml
}