import React, { useState, useCallback } from "react";
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import HeadingModule from "../components/modules/heading-module"
import { useGalleryData } from "../hooks/use-gallery-data"

class GalleryPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title;

    const HeadingContainer = () => {
      const { heading } = useGalleryData();
      return (
        <HeadingModule
          headingText={ heading.headingText }
          subHeadingText={ heading.subHeadingText }
          subHeadingMaxWidth={ heading.subHeadingMaxWidth}
          classes=""
        />
      )
    }

    const GalleryComponent = () => {
      const [currentImage, setCurrentImage] = useState(0);
      const [viewerIsOpen, setViewerIsOpen] = useState(false);

      const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
      }, []);

      const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
      };

      const createPhotosObject = ( images ) => {
        var photos = [];
        if (images && images.length) {
          images.map(({ image }) => {
            photos.push({
              src: image.childImageSharp.fixed.src,
              width: image.childImageSharp.fixed.width,
              height: image.childImageSharp.fixed.height
            });
          });
        }
        return photos;
      }
      const { gallery } = useGalleryData();
      const photos = createPhotosObject( gallery );

      return (
        <div className="contact-page">
          <div className="container">
            <div className="row">
              <HeadingContainer />
            </div>
            <div className="row">
              <div className="col-100">
                <Gallery photos={photos} margin={ 10 } onClick={openLightbox} />
                <ModalGateway>
                  {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                      <Carousel
                        currentIndex={currentImage}
                        views={photos.map(x => ({
                          ...x,
                          srcset: x.srcSet,
                          caption: x.title
                        }))}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Gallery - Niwot Inn &amp; Spa" />
        <div className="gallery-page">
          <GalleryComponent />
        </div>
      </Layout>
    );
  };
}

export default GalleryPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
